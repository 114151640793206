import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { AiOutlineUser } from "react-icons/ai";

import Input from "../../components/Input";
import Button from "../../components/Button";
import { useAuth } from "../../providers/Auth/Auth";
import { cpfMask } from "../../utils/cpfMask";

import styles from "../../styles/Login.module.scss";
import logo from "../../assets/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { FiEye, FiEyeOff, FiLock } from "react-icons/fi";

export default function QuickAccess() {
  const navigate = useNavigate();
  const { signInQuickAccess } = useAuth();
  const [loading, setLoading] = useState(false);
  const [revealPassword, setRevealPassword] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    await signInQuickAccess(cpf, password);
    navigate("/quick-access/register-point");
    setLoading(false);
  };

  function RevealPassword(event: any) {
    event.preventDefault();
    setRevealPassword(!revealPassword);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "1rem",
              width: "100%",
              maxWidth: "300px",
            }}
          >
            <div>
              <div
                style={{
                  fontFamily: "'Digital-7', monospace",
                  fontSize: "4rem",
                  color: "var(--primary)",
                  textShadow: "0 0 10px rgba(255,255,255,0.4)",
                  letterSpacing: "3px",
                  padding: "0.5rem 1rem",
                  borderRadius: "8px",
                }}
              >
                {time?.toLocaleTimeString()}
              </div>
            </div>
          </div>

          <img
            src={logo}
            alt="logo-login"
            style={{
              width: "300px",
            }}
          />

          <form onSubmit={handleLogin}>
            <Input
              name="password"
              icon={AiOutlineUser}
              placeholder="Insira seu CPF"
              maxLength={14}
              value={cpf}
              onChange={(e) => setCpf(cpfMask(e.target.value))}
            />
            <Input
              name="password"
              icon={FiLock}
              type={revealPassword ? "text" : "password"}
              placeholder="Insira sua senha"
              iconEye={revealPassword ? FiEyeOff : FiEye}
              showPassword={RevealPassword}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button type="submit" disabled={loading}>
              {loading ? <> {<Spinner size="sm" />} </> : "Login"}{" "}
            </Button>
          </form>
        </div>

        <div className={styles.background}></div>
      </div>
    </>
  );
}
