import { useCallback, useRef, useState, useEffect } from "react";
import { AiFillCamera } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import { ArrowBackPage } from "../../../components/ArrowBackPage";
import { Layout } from "../../../components/Layout";
import style from "./styles.module.scss";
import Swal from "sweetalert2";
import { SiVerizon } from "react-icons/si";
import { userApi } from "../../../providers/User/service";
import { ButtonCaptureCamera } from "../../../components/ButtonCamera/ButtonCapture";
import { customToast } from "../../../utils/toast";
import { IfLoaderInsideScreen } from "../../../components/IfLoaderInsideScreen";
import { setQuickAccess, useQuickAccess } from "../event";
import * as faceapi from "face-api.js";

export default function RegisterPoint() {
  const navigate = useNavigate();
  const webcamRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [modelsLoading, setModelsLoading] = useState(true);
  const [image, setImage] = useState(null);
  const data = useQuickAccess();
  const userData = data.userData;
  const [faceDetected, setFaceDetected] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [feedbackMessage, setFeedbackMessage] = useState(
    "Carregando modelos de detecção facial..."
  );

  useEffect(() => {
    if (!userData) {
      navigate("/quick-access");
    }
  }, [userData, navigate]);

  useEffect(() => {
    const loadModels = async () => {
      try {
        setModelsLoading(true);
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        ]);
        setModelsLoading(false);
        setFeedbackMessage("Posicione seu rosto dentro da guia");
      } catch (error) {
        console.error("Erro ao carregar modelos:", error);
        setFeedbackMessage("Erro ao carregar modelos de detecção facial");
      }
    };
    loadModels();
  }, []);

  useEffect(() => {
    let animationFrame: number;

    const detectFace = async () => {
      if (!videoRef.current) {
        console.log("Video ref não disponível");
        return;
      }

      try {
        const detection = await faceapi
          .detectSingleFace(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions({
              inputSize: 224,
              scoreThreshold: 0.5,
            })
          )
          .withFaceLandmarks();

        if (detection) {
          const { box } = detection.detection;
          const isWithinGuide = checkFaceAlignment(box);
          setFaceDetected(isWithinGuide);
        } else {
          setFaceDetected(false);
        }
      } catch (error) {
        console.error("Erro na detecção:", error);
      }

      animationFrame = requestAnimationFrame(detectFace);
    };

    if (videoRef.current && !image && !modelsLoading) {
      const videoElement = videoRef.current;
      if (videoElement.readyState === 4) {
        detectFace();
      } else {
        videoElement.addEventListener("loadeddata", detectFace);
      }
    }

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [image, modelsLoading]);

  const checkFaceAlignment = (box: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => {
    const video = videoRef.current;
    if (!video) return false;

    const videoRect = video.getBoundingClientRect();
    const videoWidth = videoRect.width;
    const videoHeight = videoRect.height;

    const scaleX = video.videoWidth / videoWidth;
    const scaleY = video.videoHeight / videoHeight;

    const scaledFaceX = box.x / scaleX;
    const scaledFaceY = box.y / scaleY;
    const scaledFaceWidth = box.width / scaleX;
    const scaledFaceHeight = box.height / scaleY;

    const tolerance = 0.15;
    const faceX = scaledFaceX + scaledFaceWidth / 2;
    const faceY = scaledFaceY + scaledFaceHeight / 2;
    const centerX = videoWidth / 2;
    const centerY = videoHeight / 2;

    const distanceX = Math.abs(faceX - centerX);
    const distanceY = Math.abs(faceY - centerY);
    const isCentered =
      distanceX < videoWidth * tolerance && distanceY < videoHeight * tolerance;

    const faceWidthRatio = scaledFaceWidth / videoWidth;
    const isGoodSize = faceWidthRatio > 0.25 && faceWidthRatio < 0.7;

    if (!isCentered) {
      if (faceX < centerX - videoWidth * tolerance) {
        setFeedbackMessage("Mova seu rosto para a direita");
      } else if (faceX > centerX + videoWidth * tolerance) {
        setFeedbackMessage("Mova seu rosto para a esquerda");
      } else if (faceY < centerY - videoHeight * tolerance) {
        setFeedbackMessage("Mova seu rosto para baixo");
      } else if (faceY > centerY + videoHeight * tolerance) {
        setFeedbackMessage("Mova seu rosto para cima");
      }
    } else if (!isGoodSize) {
      if (faceWidthRatio <= 0.25) {
        setFeedbackMessage("Aproxime-se da câmera");
      } else {
        setFeedbackMessage("Afaste-se da câmera");
      }
    } else {
      setFeedbackMessage("Posição perfeita! Pode tirar a foto");
    }

    return isCentered && isGoodSize;
  };

  const onFinish = useCallback(() => {
    navigate("/quick-access");
    setQuickAccess({
      token: null,
      userData: null,
    });
  }, [navigate]);

  const backPage = () => {
    onFinish();
  };

  const capture = useCallback(() => {
    if (!faceDetected) {
      // customToast(feedbackMessage, "warning");
      Swal.fire({
        title: "Erro",
        text: feedbackMessage,
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    }

    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
    }
  }, [webcamRef, faceDetected, feedbackMessage]);

  const handleClose = useCallback(() => {
    setImage(null);
  }, [setImage]);

  const showSuccessAlert = useCallback(() => {
    let timerInterval: NodeJS.Timeout;
    Swal.fire({
      title: `Ponto registrado com sucesso! ${userData?.name}`,
      html: "Você será deslogado em <b></b> segundos.",
      timer: 7000,
      timerProgressBar: true,
      icon: "success",
      didOpen: () => {
        const b = Swal.getHtmlContainer()?.querySelector("b");
        timerInterval = setInterval(() => {
          if (b) {
            b.textContent = String(Math.ceil(Swal.getTimerLeft()! / 1000));
          }
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
        onFinish();
      },
    });
  }, [onFinish]);

  const handleSend = useCallback(
    (isTryAgain = false) => {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        function (position: any) {
          const file = dataURLtoFile(image, "image.jpg");

          const formData = new FormData();

          formData.append("selfie", file);
          formData.append("employee_id", userData?.employee.id);
          formData.append("latitude", position.coords.latitude);
          formData.append("longitude", position.coords.longitude);
          formData.append("device_model", "Web");
          formData.append("app_version", "-");
          userApi
            .postPointRegister(userData, formData)
            .then(() => {
              setLoading(false);
              showSuccessAlert();
            })
            .catch(() => {
              if (isTryAgain) {
                setLoading(false);
                onFinish();
                customToast("Erro ao cadastrar ponto", "error");
              } else {
                setTimeout(() => {
                  handleSend(true);
                }, 1000);
              }
            });
        },
        function () {
          customToast("Erro ao cadastrar ponto", "error");
          setLoading(false);
          onFinish();
        }
      );
    },
    [image, onFinish, userData, showSuccessAlert]
  );

  return (
    <Layout hasBottomNav={false}>
      <div className={style.containerAll}>
        {!loading && (
          <div className={style.container} onClick={backPage}>
            <ArrowBackPage
              iconStyles={{
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "10px",
              }}
            />
          </div>
        )}

        {!image ? (
          <div className={style.cameraContainer}>
            <Webcam
              videoConstraints={{ facingMode: "user" }}
              className={style.camera}
              audio={false}
              ref={(webcam) => {
                webcamRef.current = webcam;
                videoRef.current = webcam?.video || null;
              }}
              mirrored={true}
              screenshotFormat="image/jpeg"
            />
            <div
              className={`${style.faceGuide} ${
                faceDetected ? style.aligned : ""
              }`}
            />
            <div className={style.feedbackText}>{feedbackMessage}</div>
            {!modelsLoading && (
              <div className={style.captureButtonContainer}>
                <ButtonCaptureCamera
                  onClick={capture}
                  component={
                    <AiFillCamera
                      size={48}
                      style={{
                        color: "#ffffff",
                        filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.3))",
                        transition: "transform 0.2s ease",
                      }}
                    />
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <div className={style.previewContainer}>
            <img src={image} alt="selfie" className={style.previewImage} />
            <div className={style.buttons}>
              <button onClick={() => handleSend()} disabled={loading}>
                <SiVerizon size={24} />
                <span style={{ marginLeft: "8px" }}>Confirmar</span>
              </button>
              <button onClick={handleClose} disabled={loading}>
                <IoMdClose size={24} />
                <span style={{ marginLeft: "8px" }}>Cancelar</span>
              </button>
            </div>
          </div>
        )}

        <IfLoaderInsideScreen loading={loading || modelsLoading} />
      </div>
    </Layout>
  );
}

export function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
