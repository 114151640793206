import { Box } from "@mui/material";
import React from "react";
import LinkSideBar from "../LinkSidebar";
import style from "./styles.module.scss";

interface LayoutProps {
  children: React.ReactNode;
  hasBottomMargin?: boolean;
  hasBottomNav?: boolean;
}

export const Layout = ({
  children,
  hasBottomMargin = true,
  hasBottomNav = true,
}: LayoutProps) => {
  return (
    <Box>
      <div
        className={`${style.containerLayout} ${
          !hasBottomMargin ? style.noBottomMargin : ""
        }`}
      >
        {children}
      </div>
      {hasBottomNav && <LinkSideBar />}
    </Box>
  );
};
