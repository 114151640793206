import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import style from "./styles.module.scss";
export const ArrowBackPage = ({iconStyles}: {iconStyles?: React.CSSProperties}) => {
  return (
    <div className={style.container}>
        <div className={style.arrow}>
            <AiOutlineArrowLeft style={iconStyles} />
        </div>
    </div>
  )
}
