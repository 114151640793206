import { Layout } from "../../components/Layout";
import { BoxContainerLayout } from "../../components/BoxContainerLayout";
import { Banner } from "../../components/Banner";
import { IoIosNotifications } from "react-icons/io";
import style from "./styles.module.scss";
import { useNotifications, setNotifications } from "../../stores/notifications";
import { switchCaseIcons } from "../../utils/switchCaseIcons";
import { useEffect, useState } from "react";
import { userApi } from "../../providers/User/service";

export default function Notify() {
  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);

  const onInit = async () => {
    setLoading(true);
    try {
      const storageUser = localStorage.getItem("@Auth:user");
      if (storageUser) {
        const userData = JSON.parse(storageUser);
        const { data } = await userApi.getNotifications(
          userData.employee.company_id
        );
        setNotifications(data?.response?.data || []);
      }
    } catch (error) {
      console.error("Failed to load notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onInit();
  }, []);

  const handleNotificationClick = async (notification: any) => {
    const storageUser = localStorage.getItem("@Auth:user");
    if (storageUser) {
      const userData = JSON.parse(storageUser);
      try {
        await userApi.readNotification(
          userData.employee.company_id,
          notification.id
        );
        setNotifications(
          notifications.map((n: any) => 
            n.id === notification.id ? { ...n, read: true } : n
          )
        );
      } catch (error) {
        console.error("Failed to mark notification as read:", error);
      }
    }
  };

  return (
    <>
      <Layout>
        <Banner text={"Notificações"} icon={<IoIosNotifications />} />
        <BoxContainerLayout>
          <div className={style.notify}>
            {loading ? (
              <p>Carregando...</p>
            ) : notifications.length === 0 ? (
              <p>Sem Notificações</p>
            ) : (
              notifications.map((notification: any, index) => (
                <div
                  key={index}
                  className={`${style.notification} ${notification.read ? style.read : ''}`}
                >
                  <div className={style.header}>
                    <div className={style.icon}>
                      {switchCaseIcons(notification.notification_type?.name)}
                    </div>
                    <h3>{notification.title}</h3>
                  </div>
                  <div
                    className={style.message}
                    dangerouslySetInnerHTML={{ __html: notification.message }}
                  />
                  <div className={style.footer}>
                    <small>
                      {new Date(notification.date_send).toLocaleDateString(
                        "pt-BR",
                        {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </small>
                    {!notification.read && (
                      <button 
                        className={style.readButton}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        Marcar como lida
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </BoxContainerLayout>
      </Layout>
    </>
  );
}
